import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-bootstrap";
import axios from "axios";
import "../../styles/body_comps/home.css";
import banner1 from "../../../images/header/banner1.jpg";
import banner2 from "../../../images/header/banner2.jpg";
import banner3 from "../../../images/header/banner3.jpg";

function Home(props) {
  const [hotelId, setHotelId] = useState("");
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [userType, setUserType] = useState("admin");
  const navigate = useNavigate();

  // State to control password visibility
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const signInHandler = (e) => {
    e.preventDefault();

    // Send a POST request to the backend
    axios
      .post(
        userType === "admin"
          ? "https://api.dectwinhotel.com/api/login"
          : "https://api.dectwinhotel.com/api/staff-login",
        {
          clientId: hotelId,
          password: password,
          staffId: userId,
        }
      )
      .then((response) => {
        if (userType === "admin") {
          localStorage.setItem("client_is", JSON.stringify(response.data));
          props.handleToast({
            toastMsg: "Congratulations! Login Successfull",
            toastColor: "#4CAF50",
          });
          navigate("/admin/dashboard");
        } else {
          const client = response.data.hotel;
          const staff = response.data.staff;
          localStorage.setItem("client_is", JSON.stringify(client));
          localStorage.setItem("staff_as", JSON.stringify(staff));
          props.handleToast({
            toastMsg: "Congratulations! Login Successfull",
            toastColor: "#4CAF50",
          });
          navigate("/admin/dashboard");
        }
      })
      .catch((error) => {
        props.handleToast({
          toastMsg: "Something went wrong! Enter valid client Id and password",
          toastColor: "#FF0000",
        });
      });
  };

  return (
    <section className="banner_main">
      {/* Carousel Section */}
      <Carousel>
        <Carousel.Item>
          <img className="d-block w-100" src={banner1} alt="First slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={banner2} alt="Second slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={banner3} alt="Third slide" />
        </Carousel.Item>
      </Carousel>

      {/* Booking Section - Left Untouched */}
      <div className="booking_ocline">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-8 col-12">
              <div className="book_room">
                <h1>Hotel Manangement</h1>
                <form
                  className="book_now"
                  onSubmit={signInHandler}
                  method="POST"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <select
                        required
                        value={userType}
                        onChange={(e) => setUserType(e.target.value)}
                        className="online_book"
                        name="user_type"
                      >
                        <option value="admin">Admin Login</option>
                        <option value="staff">Staff Login</option>
                      </select>
                      <span>Enter Hotel ID</span>
                      {/* <img className="date_cua" src="images/date.png" /> */}
                      <input
                        required
                        type="text"
                        maxLength={25}
                        value={hotelId}
                        onChange={(e) => setHotelId(e.target.value)}
                        className="online_book"
                        placeholder="Enter Hotel ID"
                        name="user_id"
                      />
                    </div>
                    {userType === "staff" && (
                      <div className="col-md-12">
                        <span>Enter Staff ID</span>
                        {/* <img className="date_cua" src="images/date.png" /> */}
                        <input
                          required
                          type="text"
                          maxLength={25}
                          value={userId}
                          onChange={(e) => setUserId(e.target.value)}
                          className="online_book"
                          placeholder="Enter Staff ID"
                          name="user_id"
                        />
                      </div>
                    )}
                    <div className="col-md-12">
                      <span>Enter Password</span>
                      {/* <img className="date_cua" src="images/date.png" /> */}
                      <div style={{ position: "relative" }}>
                        <input
                          required
                          type={showPassword ? "text" : "password"}
                          minLength={5}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          className="online_book"
                          placeholder="Enter Password"
                          name="pass"
                        />
                        <i
                          className={`fas ${
                            showPassword ? "fa-eye-slash" : "fa-eye"
                          }`}
                          onClick={togglePasswordVisibility}
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "46%",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                            color: "#ffffff",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <button type="submit" className="book_btn">
                        Login Now
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;
