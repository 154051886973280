import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import "../../../styles/admins/invoices/invoice.css";

function ExtraInvoice(props) {
  const contentRef = useRef();
  const reactToPrintFn = useReactToPrint({
    contentRef,
    onAfterPrint: () => {
      setTimeout(() => {
        props.toggleHeader();
        props.invoiceToggler();
      }, 500);
    },
  });
  const clientIs = localStorage.getItem("client_is");
  const clientDetails = JSON.parse(clientIs);

  const guests = JSON.parse(localStorage.getItem("guests"));

  // Function to get guest details by guestId
  const getGuestDetails = (guestId) => {
    const guest = guests.find((g) => g.id === guestId);
    if (guest) {
      return { name: guest.name, contact: guest.contact };
    } else {
      return {
        name: JSON.parse(props.invoiceDetails.guest_data).name[0],
        contact: "",
      };
    }
  };

  const guestData = getGuestDetails(
    JSON.parse(props.invoiceDetails.guest_data).id[0]
  );

  return (
    <section className="text-center align-items-center">
      <div className="invoice-container col-lg-6 col-md-10 col-12" ref={contentRef}>
        <div className="invoice-header">
          <h2>{clientDetails.business_name}</h2>
          <p>{clientDetails.address}</p>
          <p>Contact: {clientDetails.contact}</p>
          {clientDetails.gstin !== null && <p>GSTIN: {clientDetails.gstin}</p>}
          {/* <p>Email: info@hotelparadise.com</p> */}
          <br />
          <h2>SERVICE INVOICE</h2>
        </div>

        <div className="invoice-details">
          {" "}
          {/* Assign ref here */}
          <div className="invoice-meta text-start">
          <p>Invoice No: INV{props.invoiceDetails.booking_id}</p>
            <p>
              Booking Date: {props.invoiceDetails.from_date.slice(8, 10)}-
              {props.invoiceDetails.from_date.slice(5, 7)}-
              {props.invoiceDetails.from_date.slice(0, 4)}{" "}
              {props.invoiceDetails.from_date.slice(11, 16)}
            </p>
            <p>
              Checkout Date: {props.invoiceDetails.to_date.slice(8, 10)}-
              {props.invoiceDetails.to_date.slice(5, 7)}-
              {props.invoiceDetails.to_date.slice(0, 4)}{" "}
              {props.invoiceDetails.to_date.slice(11, 16)}
            </p>
            <p>Guest Name: {guestData.name}</p>
            <p>Guest Contact: {guestData.contact}</p>
            <p>Room No: {props.invoiceDetails.room_no}</p>
          </div>
          <table className="invoice-table">
            <thead>
              <tr>
                <th>Details</th>
                <th>Amount (₹)</th>
              </tr>
            </thead>
            <tbody>
              {props.printFood && (
                <tr>
                  <td>Food Bill</td>
                  <td>₹{props.invoiceDetails.food_price}</td>
                </tr>
              )}
              {props.printExtra && (
                <tr>
                  <td>Extra Charges</td>
                  <td>₹{props.invoiceDetails.extra_charges}</td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="invoice-total">
            <p>
              Total Amount: ₹
              {props.invoiceDetails.extra_charges +
                props.invoiceDetails.food_price}
            </p>
          </div>
          <div className="invoice-footer">
            {props.addRemarks && (
              <p style={{ fontWeight: "600" }}>
                Remarks: {props.invoiceDetails.remarks}
              </p>
            )}
            <p>Payment Method: {props.invoiceDetails.payment_method}</p>
          </div>
          <p style={{ textAlign: "center", fontWeight: "600" }}>
            Thank you for your stay!
          </p>
        </div>

        {/* Print Button */}
      </div>
      <br />
      <button className="print-btn" onClick={reactToPrintFn}>
        Print Invoice
      </button>
    </section>
  );
}

export default ExtraInvoice;
