import React from 'react';
import '../../styles/generals/reflection_card.css';
import { Link } from 'react-router-dom';

function ReflectionCard({ clientDetails }) {

    const logo = clientDetails ? clientDetails.image : "https://images.unsplash.com/photo-1621075160523-b936ad96132a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" 
    
    return (
        <div className="nft">
            <div className='main'>
                {clientDetails.pass !== undefined && <img className='tokenImage' src={logo} alt="NFT" />}
                {clientDetails.pass !== undefined && <br />}
                <h2>{clientDetails.business_name === undefined ? "Title" : clientDetails.business_name}</h2>
                <p className='description'>{clientDetails.address === undefined ? "Address" : clientDetails.address}</p>
                <p className='description'>{clientDetails.name === undefined ? "Name" : clientDetails.name}</p>
                <div className='tokenInfo'>
                    <div className="price">
                        <p>{clientDetails.contact === undefined ? "No Contact" : clientDetails.contact}</p>
                    </div>
                    <div className="duration">
                        <p>{clientDetails.gstin === null ? "NO GSTIN" : clientDetails.gstin}</p>
                    </div>
                </div>
                <hr />
                <div className='creator'>
                    {clientDetails.hotel_id === undefined ? "" : <p><ins>Client ID </ins>  {clientDetails.hotel_id}</p>}
                </div>
                <button className='card-btn'><Link to={clientDetails.btnUrl === undefined ? "/admin/edit-self" : clientDetails.btnUrl}>{clientDetails.btnTitle === undefined ? "Edit Details" : clientDetails.btnTitle}</Link></button>
            </div>
        </div>
    );
}

export default ReflectionCard;