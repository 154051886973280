import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const BillEditor = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const guests = {
    id: JSON.parse(location.state.guest_ids).id,
    name: JSON.parse(location.state.guest_ids).name,
  };

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const [formData, setFormData] = useState({
    booking_id: location.state.booking_id,
    guest_data: location.state.guest_ids,
    room_id: location.state.room_id,
    room_no: location.state.room_no,
    from_date: location.state.from_date || "",
    to_date:
      location.state.to_date === "0000-00-00 00:00:00"
        ? getCurrentDateTime()
        : location.state.to_date,
    no_of_stayers: location.state.no_of_stayers || 1,
    total_amount: location.state.total_amount || 0,
    advance_amount: location.state.advance_amount || 0,
    food_price: location.state.food_order_total,
    remarks: location.state.remarks,
    extra_charges: location.state.extra_charges || 0,
    payment_method: location.state.payment_method,
    rest_amount: location.state.last_amount,
    gst_rate: location.state.gst_rate || 0,
    gst_amount: location.state.gst_amount || 0,
  });

  // Handle form changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleNumberChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: parseFloat(value) || 0,
    }));
  };

  const calculateGSTAmount = (totalAmount, gstRate) => {
    return (totalAmount * gstRate) / 100;
  };

  const handleSubmitEdit = (e) => {
    e.preventDefault();
    formData.gst_amount = calculateGSTAmount(
      formData.total_amount,
      formData.gst_rate
    );
    formData.rest_amount =
      formData.total_amount -
      formData.advance_amount +
      formData.extra_charges +
      formData.gst_amount;

    axios
      .put(
        `https://api.dectwinhotel.com/api/${props.id}_bookings/bill-editor`,
        formData
      )
      .then((response) => {
        props.handleToast({
          toastMsg: "Invoice Edited Successfully!!!",
          toastColor: "#4CAF50",
        });
        navigate("/admin/dashboard");
      })
      .catch((error) => {
        props.handleToast({
          toastMsg: "Something went wrong! Enter please try again or check Internet",
          toastColor: "#FF0000",
        });
      });
  };

  return (
    <section style={{ padding: "4rem 0" }}>
      <div className="book-room-form">
        <h2>Edit Invoice No INV{location.state.booking_id}</h2>
        <form onSubmit={handleSubmitEdit}>
          {guests.id.length > 0 &&
            guests.id.map((ids, index) => (
              <div key={ids} className="guest-entry">
                <label htmlFor="guest_name">Guest {index + 1} Name</label>
                <input
                  type="text"
                  name="guest_name"
                  value={guests.name[index]}
                  readOnly
                />
              </div>
            ))}

          <label htmlFor="from_date">From Date</label>
          <input
            type="datetime-local"
            name="from_date"
            value={formData.from_date}
            readOnly
          />

          <label htmlFor="to_date">To Date</label>
          <input
            type="datetime-local"
            name="to_date"
            value={formData.to_date}
            readOnly
          />

          <label htmlFor="no_of_stayers">Number of Adult(s)</label>
          <select
            name="no_of_stayers"
            value={formData.no_of_stayers}
            readOnly
            disabled
          >
            <option value="">{formData.no_of_stayers}</option>
          </select>

          <label htmlFor="total_amount">Total Amount</label>
          <input
            type="text"
            maxLength={7}
            name="total_amount"
            value={formData.total_amount}
            onChange={handleNumberChange}
            required
          />

          <label htmlFor="advance_amount">Advance Amount</label>
          <input
            type="text"
            name="advance_amount"
            value={formData.advance_amount}
            maxLength={7}
            onChange={handleNumberChange}
            required
          />

          <label htmlFor="payment_method">Payment Method</label>
          <select
            name="payment_method"
            value={formData.payment_method}
            onChange={handleChange}
            required
          >
            <option value="">Select Payment Method</option>
            <option value="Cash">Cash</option>
            <option value="Unified Payment Interface (UPI)">
              Unified Payment Interface (UPI)
            </option>
            <option value="Credit Card">Credit Card</option>
            <option value="Debit Card">Debit Card</option>
          </select>

          <label htmlFor="rest_amount">Remaining Amount to Pay</label>
          <input
            type="text"
            name="rest_amount"
            value={
              formData.total_amount -
              formData.advance_amount +
              formData.extra_charges +
              calculateGSTAmount(formData.total_amount, formData.gst_rate)
            }
            readOnly
            required
          />

          <label htmlFor="remarks">Remarks</label>
          <textarea
            name="remarks"
            placeholder="Add any remarks here"
            value={formData.remarks}
            maxLength={300}
            onChange={handleChange}
          />

          <label htmlFor="food_charges">Food Order Amount</label>
          <input
            type="text"
            name="food_charges"
            value={formData.food_price}
            maxLength={7}
            onChange={handleNumberChange}
            required
          />

          <label htmlFor="extra_charges">Extra Charges</label>
          <input
            type="text"
            name="extra_charges"
            maxLength={7}
            value={formData.extra_charges}
            onChange={handleNumberChange}
            required
          />

          <div className="gst-row">
            <label htmlFor="gst_rate">GST Rate</label>
            <input
              type="text"
              name="gst_rate"
              maxLength={4}
              value={formData.gst_rate}
              onChange={handleNumberChange}
              required
            />

            <label htmlFor="gst_amount">GST Amount</label>
            <input
              type="text"
              name="gst_amount"
              maxLength={7}
              value={calculateGSTAmount(
                formData.total_amount,
                formData.gst_rate
              )}
              readOnly
              required
            />
          </div>

          <br />
          <button style={{ backgroundColor: "#007bff" }} type="submit">
            Confirm Editing Invoice
          </button>
        </form>
      </div>
    </section>
  );
};

export default BillEditor;
