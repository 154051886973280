import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import "../../../styles/admins/rooms/book_room.css";
import GuestModal from "../invoices/guest";

const EditBooking = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [guests, setGuests] = useState([]);

  useEffect(() => {
    const fetchGuests = async () => {
      const allGuests = JSON.parse(localStorage.getItem("guests"));
      const ids = JSON.parse(location.state.guest_ids).id;
      const addedGuests = [];

      for (const id of ids) {
        const guest = allGuests.find((g) => g.id === id);
        if (guest) {
          addedGuests.push(guest);
        } else {
          try {
            const response = await axios.get(
              `https://api.dectwinhotel.com/api/${props.id}_guests/guests/${id}`
            );
            if (response.status === 200) {
              addedGuests.push(response.data[0]);
            }
          } catch (err) {
            console.error(err);
          }
        }
      }
      setGuests(addedGuests);
    };

    fetchGuests();
  }, [location.state.guest_ids, props.id]);

  const [showGuestModal, setShowGuestModal] = useState(false);

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const [formData, setFormData] = useState({
    booking_id: location.state.booking_id,
    guest_data: location.state.guest_ids,
    room_id: location.state.room_id,
    room_no: location.state.room_no,
    from_date: location.state.from_date || "",
    to_date:
      location.state.to_date === "0000-00-00 00:00:00"
        ? getCurrentDateTime()
        : location.state.to_date,
    no_of_stayers: location.state.no_of_stayers || 1,
    total_amount: location.state.total_amount || 0,
    advance_amount: location.state.advance_amount || 0,
    remarks: "",
    food_price: location.state.food_order_total,
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle change in number input
  const handleNumberChange = (e) => {
    const value = e.target.value;

    if (value.length <= 10 && /^[0-9]*$/.test(value)) {
      if (
        e.target.name === "phone" &&
        value.length > 0 &&
        !["6", "7", "8", "9"].includes(value[0])
      ) {
        setFormData({ ...formData, [e.target.name]: "" });
        props.handleToast({
          toastMsg: "Invalid Contact! Please enter a valid mobile number",
          toastColor: "#FF0000",
        });
      } else {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (guests.length < 1) {
      props.handleToast({
        toastMsg: "Something went wrong! Please Add atleast one guest.",
        toastColor: "#FF0000",
      });
      return;
    }

    const guestIds = guests.map((guest) => guest.id);
    const guestNames = guests.map((guest) => guest.name);

    formData.guest_ids = JSON.stringify({ id: guestIds, name: guestNames });

    try {
      const response = await axios.put(
        `https://api.dectwinhotel.com/api/${props.id}_bookings/edit-checkin`,
        formData
      );
      if (response.status === 201) {
        props.handleToast({
          toastMsg: "Check In Updated Successfully!!!",
          toastColor: "#4CAF50",
        });
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        navigate("/admin/rooms");
      } else {
        props.handleToast({
          toastMsg: "Something went wrong! Please check data or internet.",
          toastColor: "#FF0000",
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const openGuestModal = () => setShowGuestModal(true);
  const closeGuestModal = () => setShowGuestModal(false);

  const addGuestToForm = (guest) => {
    setGuests([...guests, guest]);
  };

  const removeGuest = async (guestId) => {
    try {
      // Remove from the database
      await axios.delete(
        `https://api.dectwinhotel.com/api/${props.id}_guests/remove-guest/${guestId}`
      );

      // Remove from local storage
      localStorage.removeItem(guestId);

      // Remove from UI
      setGuests(guests.filter((guest) => guest.id !== guestId));
    } catch (err) {
      console.error("Error removing guest", err);
    }
  };

  return (
    <section style={{ padding: "1rem 0" }}>
      <div className="book-room-form">
        <h2>Edit Checkin Room No {formData.room_no}</h2>
        <form onSubmit={handleSubmit}>
          {guests.length > 0 &&
            guests.map((guest) => (
              <div key={guest.id} className="guest-entry">
                <label htmlFor="guest_name">Guest Name</label>
                <input
                  type="text"
                  name="guest_name"
                  placeholder="Guest Name"
                  value={guest.name}
                  onChange={handleChange}
                  required
                />
                <button type="button" onClick={() => removeGuest(guest.id)}>
                  Remove Guest
                </button>
              </div>
            ))}

          {guests.length > 0 && <br />}

          <button type="button" onClick={openGuestModal}>
            Add Guest
          </button>

          <label htmlFor="from_date">From Date</label>
          <input
            type="datetime-local"
            name="from_date"
            value={formData.from_date}
            onChange={handleChange}
            required
          />

          <label htmlFor="to_date">To Date</label>
          <input
            type="datetime-local"
            name="to_date"
            value={formData.to_date}
            onChange={handleChange}
          />

          <label htmlFor="no_of_stayers">Number of Adult(s)</label>
          <select
            name="no_of_stayers"
            value={formData.no_of_stayers}
            onChange={handleChange}
            required
          >
            <option value="">Select Number of Adult(s)</option>
            {Array.from({ length: 8 }, (_, i) => i + 1).map((num) => (
              <option key={num} value={num}>
                {num}
              </option>
            ))}
          </select>

          <label htmlFor="total_amount">Total Amount</label>
          <input
            type="text"
            name="total_amount"
            placeholder="Total Amount"
            maxLength={7}
            value={formData.total_amount}
            onChange={handleNumberChange}
            required
          />

          <label htmlFor="paid_amount">Advance Amount</label>
          <input
            type="text"
            name="advance_amount"
            placeholder="Advance Paid"
            maxLength={7}
            value={formData.advance_amount}
            onChange={handleNumberChange}
            required
          />

          <label htmlFor="remarks">Remarks</label>
          <textarea
            name="remarks"
            placeholder="Add any remarks here"
            value={formData.remarks}
            onChange={handleChange}
          />

          <button type="submit">Edit Checkin Now</button>
        </form>
      </div>
      {showGuestModal && (
        <GuestModal
          onClose={closeGuestModal}
          addGuestToForm={addGuestToForm}
          handleToast={props.handleToast}
        />
      )}
    </section>
  );
};

export default EditBooking;
