import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const modalOverlayStyle = {
  position: "fixed",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

function ProtectedLink({ staffData, hotelData, report, handleToast }) {
  //Password for staff
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  // Placeholder function for password verification
  const checkPassword = () => {
    if (password === hotelData.pass) {
      navigate("/admin/editor-bill", { state: report });
    } else {
      setShowPasswordModal(false);
      handleToast({
        toastMsg: "Please Enter correct password",
        toastColor: "#FF0000",
      });
    }
  };

  // Handle the click on the link
  const handleClick = (e) => {
    if (staffData) {
      e.preventDefault();
      setShowPasswordModal(true);
    }
  };

  return (
    <div>
      <Link to="/admin/editor-bill" onClick={handleClick} state={report}>
        <i title="Edit Invoice" className="fa fa-edit"></i>
      </Link>

      {showPasswordModal && (
        <div style={modalOverlayStyle}>
          <div className="model-content">
            <h2>Enter Password</h2>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter password"
            />
            <br />
            <br />
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <button className="confirm-button-style" onClick={checkPassword}>
                Submit
              </button>
              <button
                className="cancel-button-style"
                onClick={() => setShowPasswordModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProtectedLink;
