import React from "react";
import { useNavigate } from "react-router-dom";
import bgImag from "../../../images/logout.jpg";

const containerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "2rem",
  boxSizing: "border-box",
  backgroundColor: "#282c34",
};

const sectionStyle = {
  backgroundImage: `url(${bgImag})`,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  width: "100vw",
  height: "85vh",
  borderRadius: "10px",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  position: "relative",
  paddingTop: "25px",
};

const modalOverlayStyle = {
  width: "max-content",
  backgroundColor: "rgba(255, 255, 255, 0.9)",
  padding: "2em 3em",
  borderRadius: "8px",
  textAlign: "center",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
};

const buttonStyle = {
  padding: "10px 20px",
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
};

function Logout() {
  const navigate = useNavigate();

  const onLogout = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };

  return (
    <section style={containerStyle}>
      <div style={sectionStyle}>
        <div style={modalOverlayStyle}>
          <h2>Are you sure?</h2>
          <p>Do you really want to logout?</p>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <button
              onClick={onLogout}
              style={{ ...buttonStyle, backgroundColor: "red", color: "white" }}
            >
              Logout
            </button>
            <button
              onClick={() => navigate(-1)}
              style={{
                ...buttonStyle,
                backgroundColor: "blue",
                color: "white",
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Logout;
