import React, { useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import "../../../styles/admins/rooms/book_room.css";
import GuestModal from "../invoices/guest";

function AdvanceBooking(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [guests, setGuests] = useState([]);
  const [showGuestModal, setShowGuestModal] = useState(false);

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const [formData, setFormData] = useState({
    guest_ids: `{"ids":[]}`,
    from_date: getCurrentDateTime(),
    to_date: "",
    no_of_stayers: "",
    payment_method: "",
    total_amount: location.state.price, // New field for paid amount
    advance_amount: "", // New field for paid amount
    remarks: "", // New field for paid amount
    room_id: location.state.room_id,
    room_no: location.state.room_no,
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle change in number input
  const handleNumberChange = (e) => {
    const value = e.target.value;

    if (value.length <= 10 && /^[0-9]*$/.test(value)) {
      if (
        e.target.name === "phone" &&
        value.length > 0 &&
        !["6", "7", "8", "9"].includes(value[0])
      ) {
        setFormData({ ...formData, [e.target.name]: "" });
        props.handleToast({
          toastMsg: "Invalid Contact! Please enter a valid mobile number",
          toastColor: "#FF0000",
        });
      } else {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      }
    }
  };

  // const handleFileChange = (e) => {
  //   const { name, files } = e.target;

  //   setFormData({ ...formData, [name]: files[0] });
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (guests.length < 1) {
      props.handleToast({
        toastMsg: "Something went wrong! Please Add atleast one guest.",
        toastColor: "#FF0000",
      });
      return;
    }
    const guestIds = guests.map((guest) => guest.id);
    const guestNames = guests.map((guest) => guest.name);
    formData.guest_ids = JSON.stringify({ id: guestIds, name: guestNames });

    try {
      const response = await axios.post(
        `https://api.dectwinhotel.com/api/${props.id}_advance_bookings/advance-booking`,
        formData
      );
      if (response.status === 201) {
        props.handleToast({
          toastMsg: "Advance Booking Done Successfully!!!",
          toastColor: "#4CAF50",
        });
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        navigate("/admin/rooms");
      } else {
        props.handleToast({
          toastMsg: "Something went wrong! Please check data or internet.",
          toastColor: "#FF0000",
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const openGuestModal = () => setShowGuestModal(true);
  const closeGuestModal = () => setShowGuestModal(false);

  const addGuestToForm = (guest) => {
    setGuests([...guests, guest]);
  };

  const removeGuest = async (guestId) => {
    try {
      // Remove from the database
      await axios.delete(
        `https://api.dectwinhotel.com/api/${props.id}_guests/remove-guest/${guestId}`
      );

      // Remove from local storage
      localStorage.removeItem(guestId);

      // Remove from UI
      setGuests(guests.filter((guest) => guest.id !== guestId));
    } catch (err) {
      console.error("Error removing guest", err);
    }
  };

  return (
    <section style={{ padding: "1rem" }}>
      <div className="book-room-form">
        <h2>Book Room No {location.state.rooom_number}</h2>
        <form onSubmit={handleSubmit}>
          {guests.length > 0 &&
            guests.map((guest) => (
              <div key={guest.id} className="guest-entry">
                <label htmlFor="guest_name">Guest Name</label>
                <input
                  type="text"
                  name="guest_name"
                  placeholder="Guest Name"
                  value={guest.name}
                  onChange={handleChange}
                  required
                />
                <button type="button" onClick={() => removeGuest(guest.id)}>
                  Remove Guest
                </button>
              </div>
            ))}
          {guests.length > 0 && <br />}
          <button type="button" onClick={openGuestModal}>
            Add Guest
          </button>

          <label htmlFor="from_date">From Date</label>
          <input
            type="datetime-local"
            name="from_date"
            value={formData.from_date}
            onChange={handleChange}
            required
          />

          <label htmlFor="to_date">To Date</label>
          <input
            type="datetime-local"
            name="to_date"
            value={formData.to_date}
            onChange={handleChange}
            required
          />

          <label htmlFor="no_of_stayers">Number of Adult(s)</label>
          <select
            name="no_of_stayers"
            value={formData.no_of_stayers}
            onChange={handleChange}
            required
          >
            <option value="">Select Number of Adult(s)</option>
            {Array.from(
              { length: location.state.max_occupancy },
              (_, i) => i + 1
            ).map((num) => (
              <option key={num} value={num}>
                {num}
              </option>
            ))}
          </select>

          <label htmlFor="paid_amount">Total Amount</label>
          <input
            type="text"
            name="total_amount"
            placeholder="Total Amount"
            maxLength={7}
            value={formData.total_amount}
            onChange={handleNumberChange}
            required
          />

          <label htmlFor="payment_method">Payment Method</label>
          <select
            name="payment_method"
            value={formData.payment_method}
            onChange={handleChange}
            required
          >
            <option value="">Select Payment Method</option>
            <option value="Cash">Cash</option>
            <option value="Unified Payment Interface (UPI)">
              Unified Payment Interface (UPI)
            </option>
            <option value="Credit Card">Credit Card</option>
            <option value="Debit Card">Debit Card</option>
          </select>

          <label htmlFor="paid_amount">Advance Amount</label>
          <input
            type="text"
            name="advance_amount"
            placeholder="Advance Paid"
            maxLength={7}
            value={formData.advance_amount}
            onChange={handleNumberChange}
            required
          />

          <label htmlFor="remarks">Remarks</label>
          <textarea
            name="remarks"
            placeholder="Add any remarks here"
            value={formData.remarks}
            onChange={handleChange}
          />

          <button type="submit">Book Room in Advance</button>
        </form>
      </div>
      {showGuestModal && (
        <GuestModal
          onClose={closeGuestModal}
          addGuestToForm={addGuestToForm}
          handleToast={props.handleToast}
        />
      )}
    </section>
  );
}

export default AdvanceBooking;
