import React from "react";
import { NavLink, Link } from "react-router-dom";
import "../../styles/generals/header.css";
import logo from "../../../images/header/logo.png";

function Header({ hasLogin, logoImg }) {
  if (!logoImg) {
    logoImg = logo;
  }
  return (
    <header>
      <div className="header">
        <div className="container" style={{ padding: "2px 0px" }}>
          <div className="d-flex justify-content-between">
            <div className="logo">
              <div>
                {" "}
                <Link to="/">
                  <img src={logoImg} className="logo" alt="logo" />
                </Link>{" "}
              </div>
            </div>
            <div className="nav-bar">
              {hasLogin ? (
                <div className="menu-area">
                  <nav className="main-menu">
                        <NavLink className='main-menu-text' to="/admin/dashboard">Dashboard</NavLink>
                        <NavLink className='main-menu-text' to="/admin/rooms">Rooms</NavLink>
                        <NavLink className='main-menu-text' to="/admin/foods">Foods</NavLink>
                        <NavLink className='main-menu-text' to="/admin/reports">Reports</NavLink>
                        <NavLink className='main-menu-text' to="/admin/logout">Logout</NavLink>
                  </nav>
                </div>
              ) : (
                <h5 className="pt-1" style={{ textAlign: "center" }}>
                  Welcome to Dectwin Hotel Management Software
                </h5>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
