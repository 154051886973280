import React, { useEffect } from "react";
import "../../styles/admins/dashboard.css";
import ReflectionCard from "../generals/reflection_card";
import {
  fetchFoodsData,
  fetchGuestData,
  fetchRoomsData,
} from "../confs/apis_at_once";

const addRooms = {
  business_name: "Add Room",
  address:
    "Keep your room inventories up-to-date for smooth working operations and better guest satisfaction.",
  name: "",
  contact: "",
  btnTitle: "+ Add Rooms",
  btnUrl: "/admin/add-room",
};

const addFoods = {
  business_name: "Add Food",
  address:
    "Keep your menu current and flexible, allowing seamless food management and improved dining experiences.",
  name: "",
  contact: "",
  btnTitle: "+ Add Foods",
  btnUrl: "/admin/add-food",
};

const advanceBooking = {
  business_name: "Advance Booking",
  address:
    "Enable guests to reserve rooms in advance, ensuring smooth planning and a better stay experience.",
  name: "",
  contact: "",
  btnTitle: "+ View Bookings",
  btnUrl: "/admin/advance-history",
};

const addStaff = {
  business_name: "Add Staff",
  address:
    "Maintain a well-organized staff directory, ensuring efficient operations and seamless service delivery to guests.",
  name: "",
  contact: "",
  btnTitle: "+ Add Staff",
  btnUrl: "/admin/add-staff",
};

function AdminDashboard({ clientDetails }) {
  useEffect(() => {
    const loadDashboardData = async (id) => {
      try {
        // Fetch all data in parallel
        await Promise.all([
          fetchRoomsData(id),
          fetchFoodsData(id),
          fetchGuestData(id),
        ]);
      } catch (error) {
        console.log(error);
      }
    };

    // Check if data exists in localStorage to prevent re-fetching
    const storedData = localStorage.getItem("rooms");
    const storedFoods = localStorage.getItem("foods");
    const storedGuests = localStorage.getItem("guests");
    if (!storedData || !storedFoods || !storedGuests) {
      loadDashboardData(clientDetails.id);
    }
  }, [clientDetails.id]);

  return (
    <section className="row">
      <div className="col-lg-4 col-12">
        <ReflectionCard clientDetails={clientDetails} />
      </div>
      <div className="col-lg-4 col-md-6 col-12">
        <ReflectionCard clientDetails={addRooms} />
        <ReflectionCard clientDetails={addFoods} />
      </div>
      <div className="col-lg-4 col-md-6 col-12">
        <ReflectionCard clientDetails={advanceBooking} />
        <ReflectionCard clientDetails={addStaff} />
      </div>
    </section>
  );
}

export default AdminDashboard;
