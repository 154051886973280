import React, { useState, useEffect } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/admins/reports.css";
import { Link } from "react-router-dom";
import ProtectedLink from "../confs/protected_link";

const ReportPage = ({ id, handleToast }) => {
  const dateTen = new Date();
  dateTen.setDate(dateTen.getDate() - 10);
  const [startDate, setStartDate] = useState(dateTen);
  const [endDate, setEndDate] = useState(new Date());
  const [filteredReports, setFilteredReports] = useState([]);
  const [filters, setFilters] = useState({
    roomId: "",
    guestName: "",
    checkoutStatus: "All",
  });
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  //Check for staff
  const staffData = JSON.parse(localStorage.getItem("staff_as"));
  const hotelData = JSON.parse(localStorage.getItem("client_is"));

  // Handle form submit for fetching reports
  const fetchReports = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        `https://api.dectwinhotel.com/api/${id}_bookings/reports`,
        {
          startDate,
          endDate,
        }
      );
      setReports(response.data);
      setFilteredReports(response.data);
    } catch (err) {
      setError("Failed to fetch reports.");
    } finally {
      setLoading(false);
    }
  };

  // Update filter state when filter inputs change
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  // Apply all filters whenever any filter value changes
  useEffect(() => {
    const { roomId, guestName, checkoutStatus } = filters;

    const filteredData = reports.filter((report) => {
      // Filter by roomId if specified
      const matchesRoomId = roomId
        ? report.room_no.toLowerCase().includes(roomId.toLowerCase())
        : true;

      // Filter by guestName if specified
      const matchesGuestName = guestName
        ? JSON.parse(report.guest_ids)
          .name[0].toLowerCase()
          .includes(guestName.toLowerCase())
        : true;

      // Filter by checkoutStatus if specified
      const matchesCheckoutStatus =
        checkoutStatus === "All"
          ? true
          : (report.checkout_status ? "Checked Out" : "Checked In") ===
          checkoutStatus;

      // Include only reports that match all criteria
      return matchesRoomId && matchesGuestName && matchesCheckoutStatus;
    });

    setFilteredReports(filteredData);
  }, [filters, reports]);

  return (
    <section
      style={{
        backgroundColor: "#282c34",
        padding: "4rem 1rem",
      }}
    >
      <div className="reports-page">
        <h2>View Reports</h2>
        <div className="row my-4">
          <div className="col-lg-4 col-12 my-2">
            <label>Date Range:</label>
            <div className="date-picker">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
              <span>to</span>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
              />
            </div>
          </div>

          <div className="col-lg-2 col-12 my-2">
            <label>Room Number:</label>
            <input
              type="text"
              name="roomId"
              value={filters.roomId}
              onChange={handleFilterChange}
              placeholder="Enter room number"
            />
          </div>

          <div className="col-lg-2 col-12 my-2">
            <label>Guest Name:</label>
            <input
              type="text"
              name="guestName"
              value={filters.guestName}
              onChange={handleFilterChange}
              placeholder="Enter guest name"
            />
          </div>

          <div className="col-lg-2 col-12 my-2">
            <label>Status:</label>
            <select
              name="checkoutStatus"
              onChange={handleFilterChange}
              value={filters.checkoutStatus}
            >
              <option value="All">All</option>
              <option value="Checked In">Checked In</option>
              <option value="Checked Out">Checked Out</option>
              {/* <option value="advance-booking">Advance Booking</option> */}
            </select>
          </div>

          <div className="col-lg-2 col-12 my-2 pt-4">
          <button className="fetch-btn mt-2" onClick={fetchReports}>
              Fetch Reports
            </button></div>

        </div>

        {/* Display loading, error, or reports */}
        {loading && <p>Loading reports...</p>}
        {error && <p>{error}</p>}

        {!loading && reports.length > 0 && (
          <div className="table-responsive">
            <table className="reports-table">
              <thead>
                <tr>
                  <th>Booking ID</th>
                  <th>Guest Name</th>
                  <th>Room No</th>
                  <th>Check-in Date</th>
                  <th>Check-out Date</th>
                  <th>Status</th>
                  <th>Total Amount</th>
                  <th>Activity</th>
                </tr>
              </thead>
              <tbody>
                {filteredReports.map((report) => (
                  <tr key={report.booking_id}>
                    <td>{report.booking_id}</td>
                    <td>{JSON.parse(report.guest_ids).name[0]}</td>
                    <td>{report.room_no}</td>
                    <td>{new Date(report.from_date).toLocaleString()}</td>
                    <td>{new Date(report.to_date).toLocaleString()}</td>
                    <td>
                      {report.checkout_status ? "Checked Out" : "Checked In"}
                    </td>
                    <td>₹{report.total_amount}</td>
                    <td>
                      <div className="d-flex justify-content-around">
                        {report.checkout_status ? (
                          <Link to="/admin/reprint" state={report}>
                            <i title="Print Invoice" className="fa fa-file"></i>
                          </Link>
                        ) : (
                          <i
                            title="Re-print: Only after check out"
                            className="fa fa-file"
                          ></i>
                        )}
                        {report.checkout_status ? (
                          <ProtectedLink staffData={staffData} hotelData={hotelData} report={report} handleToast={handleToast} />
                        ) : (
                          <i
                            title="Edit Invoice: Only after check out"
                            className="fa fa-edit"
                          ></i>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {!loading && reports.length === 0 && (
          <p>No reports found for the selected criteria.</p>
        )}
      </div>
    </section>
  );
};

export default ReportPage;
