import React, { useState, useEffect } from "react";
import axios from "axios";
import FoodCard from "../../generals/food_card";

function AdminFoods({ handleToast, id }) {
  const [foods, setFoods] = useState([]);

  useEffect(() => {
    // localStorage.removeItem("foods");
    // Check if products are already in localStorage
    const storedFoods = localStorage.getItem("foods");
    if (storedFoods) {
      // Use cached data if available
      setFoods(JSON.parse(storedFoods));
    } else {
      // Fetch products from the API
      axios
        .get(`https://api.dectwinhotel.com/api/${id}_foods/foods`)
        .then((response) => {
          setFoods(response.data);
          // Store products in localStorage
          localStorage.setItem("foods", JSON.stringify(response.data));
        })
        .catch((error) => {
          console.error("Error fetching products:", error);
        });
    }
  }, [id]);

  return (
    <section>
      <div className="rooms-data">
        {foods.length > 0 ? (
          foods.map((food) => (
            <FoodCard
              key={food.food_id}
              foodData={food}
              bookingId={null}
              updateTotals={function () {}}
              handleToast={handleToast}
              id={id}
            />
          ))
        ) : (
          <div className="no-rooms-message">
            <i
              style={{ fontSize: "6rem", margin: "30px 10px" }}
              className="fa fa-cutlery"
            ></i>
            <p>No Food Items Added Yet.</p>
          </div>
        )}
      </div>
    </section>
  );
}

export default AdminFoods;
