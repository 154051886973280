import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./App.css";
import Header from "./components/scripts/generals/header";
import Home from "./components/scripts/body_comps/home";
import AdminDashboard from "./components/scripts/admins/dashboard";
import MyToast from "./components/scripts/generals/my_toast";
import AddFood from "./components/scripts/admins/foods/add_food";
import AddRooms from "./components/scripts/admins/rooms/add_rooms";
import AdminRooms from "./components/scripts/admins/rooms/rooms";
import BookRoom from "./components/scripts/admins/rooms/book_room";
import AdminFoods from "./components/scripts/admins/foods/foods";
import CheckoutPage from "./components/scripts/admins/rooms/check_out";
import AdvanceBooking from "./components/scripts/admins/rooms/advance_book";
import Reports from "./components/scripts/admins/reports";
import AdvanceBookingHistory from "./components/scripts/admins/rooms/adv_book_history";
import AddStaff from "./components/scripts/admins/add_staff";
import Reprint from "./components/scripts/admins/reprint";
import EditBooking from "./components/scripts/admins/rooms/edit_booking";
import PageNotFound from "./components/scripts/body_comps/page_not_found";
import Logout from "./components/scripts/body_comps/logout";
import EditSelfDetails from "./components/scripts/admins/edit_self";
import BillEditor from "./components/scripts/admins/bill-editor";

function App() {
  const [showToast, setShowToast] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const [toastColor, setToastColor] = useState("#4CAF50");
  const [toastMsg, setToastMsg] = useState("Message");

  const clientIs = localStorage.getItem("client_is");
  // localStorage.removeItem('client_is');

  const isLoggedIn = clientIs === null;
  const clientDetails = JSON.parse(clientIs);

  function handleToastData(data) {
    setShowToast(true);
    setToastColor(data.toastColor);
    setToastMsg(data.toastMsg);
    setTimeout(() => {
      setShowToast(false);
    }, 3000);
  }

  function closeToast() {
    setShowToast(false);
  }

  function toggleHeader() {
    setShowHeader(!showHeader);
  }

  return (
    <Router>
      <MyToast
        message={toastMsg}
        color={toastColor}
        show={showToast}
        onClose={closeToast}
      />

      {showHeader && (
        <Header
          hasLogin={!isLoggedIn}
          logoImg={clientDetails ? clientDetails.image : null}
        />
      )}

      {isLoggedIn ? (
        <Routes>
          <Route
            exact
            path="/"
            element={<Home handleToast={handleToastData} />}
          ></Route>

          <Route exact path="*" element={<PageNotFound />}></Route>
        </Routes>
      ) : (
        <Routes>
          <Route
            exact
            path="/"
            element={<AdminDashboard clientDetails={clientDetails} />}
          ></Route>

          <Route
            exact
            path="/admin/dashboard"
            element={<AdminDashboard clientDetails={clientDetails} />}
          ></Route>

          <Route
            exact
            path="/admin/edit-self"
            element={
              <EditSelfDetails
                handleToast={handleToastData}
                clientDetails={clientDetails}
              />
            }
          ></Route>

          <Route
            exact
            path="/admin/rooms"
            element={
              <AdminRooms handleToast={handleToastData} id={clientDetails.id} />
            }
          ></Route>

          <Route
            exact
            path="/admin/foods"
            element={
              <AdminFoods handleToast={handleToastData} id={clientDetails.id} />
            }
          ></Route>

          <Route
            exact
            path="/admin/add-room"
            element={
              <AddRooms handleToast={handleToastData} id={clientDetails.id} />
            }
          ></Route>

          <Route
            exact
            path="/admin/book-room"
            element={
              <BookRoom handleToast={handleToastData} id={clientDetails.id} />
            }
          ></Route>

          <Route
            exact
            path="/admin/edit-booking"
            element={
              <EditBooking
                handleToast={handleToastData}
                id={clientDetails.id}
              />
            }
          ></Route>

          <Route
            exact
            path="/admin/leave-room"
            element={
              <CheckoutPage
                handleToast={handleToastData}
                id={clientDetails.id}
                toggleHeader={toggleHeader}
              />
            }
          ></Route>

          <Route
            exact
            path="/admin/reports"
            element={
              <Reports handleToast={handleToastData} id={clientDetails.id} />
            }
          ></Route>

          <Route
            exact
            path="/admin/book-advance"
            element={
              <AdvanceBooking
                handleToast={handleToastData}
                id={clientDetails.id}
              />
            }
          ></Route>

          <Route
            exact
            path="/admin/advance-history"
            element={
              <AdvanceBookingHistory
                handleToast={handleToastData}
                id={clientDetails.id}
              />
            }
          ></Route>

          <Route
            exact
            path="/admin/add-food"
            element={
              <AddFood handleToast={handleToastData} id={clientDetails.id} />
            }
          ></Route>

          <Route
            exact
            path="/admin/add-staff"
            element={
              <AddStaff handleToast={handleToastData} id={clientDetails.id} />
            }
          ></Route>

          <Route
            exact
            path="/admin/reprint"
            element={
              <Reprint
                handleToast={handleToastData}
                id={clientDetails.id}
                toggleHeader={toggleHeader}
              />
            }
          ></Route>

          <Route
            exact
            path="/admin/editor-bill"
            element={
              <BillEditor handleToast={handleToastData} id={clientDetails.id} />
            }
          ></Route>

          <Route
            exact
            path="/admin/logout"
            element={
              <Logout handleToast={handleToastData} id={clientDetails.id} />
            }
          ></Route>

          <Route exact path="*" element={<PageNotFound />}></Route>
        </Routes>
      )}

      {/* <Footer /> */}
    </Router>
  );
}

export default App;
