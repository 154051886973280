import React from "react";

const modalOverlayStyle = {
  position: "fixed",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};


function DeleteFood({foodName, onDelete, showModals}) {
  return showModals && (
    <div style={modalOverlayStyle}>
      <div className="model-content">
        <h2>Are you sure?</h2>
        <p>Do you really want to delete the food item "<strong>{foodName}</strong>"?</p>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <button onClick={onDelete} className="confirm-button-style">
            Yes, Delete
          </button>
          <button onClick={showModals} className="cancel-button-style">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeleteFood;
