import React, { useState, useRef } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import "../../../styles/admins/rooms/book_room.css";
import GuestModal from "../invoices/guest";

const BookRoom = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [cameraStarted, setCameraStarted] = useState(false);
  const [guests, setGuests] = useState([]);
  const [showGuestModal, setShowGuestModal] = useState(false);

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const [formData, setFormData] = useState({
    guest_ids: `{"ids":[], "name":[]}`,
    from_date: getCurrentDateTime(),
    to_date: "",
    no_of_stayers: "",
    live_image: null,
    live_image_file: null,
    total_amount: location.state.price, // New field for paid amount
    advance_amount: "", // New field for paid amount
    room_id: location.state.room_id,
    room_no: location.state.room_number,
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle change in number input
  const handleNumberChange = (e) => {
    const value = e.target.value;

    if (value.length <= 10 && /^[0-9]*$/.test(value)) {
      if (
        e.target.name === "phone" &&
        value.length > 0 &&
        !["6", "7", "8", "9"].includes(value[0])
      ) {
        setFormData({ ...formData, [e.target.name]: "" });
        props.handleToast({
          toastMsg: "Invalid Contact! Please enter a valid mobile number",
          toastColor: "#FF0000",
        });
      } else {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      }
    }
  };

  // const handleFileChange = (e) => {
  //   const { name, files } = e.target;

  //   setFormData({ ...formData, [name]: files[0] });
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (guests.length < 1) {
      props.handleToast({
        toastMsg: "Something went wrong! Please Add atleast one guest.",
        toastColor: "#FF0000",
      });
      return;
    }
    const data = new FormData();
    data.append("from_date", formData.from_date);
    data.append("to_date", formData.to_date);
    data.append("no_of_stayers", formData.no_of_stayers);

    // Append the file for live image (not the base64 string)
    if (formData.live_image_file) {
      data.append("live_image", formData.live_image_file);
    }

    data.append("total_amount", formData.total_amount);
    data.append("advance_amount", formData.advance_amount);
    data.append("room_id", formData.room_id);
    data.append("room_no", formData.room_no);

    const guestIds = guests.map((guest) => guest.id);
    const guestNames = guests.map((guest) => guest.name);

    data.append(
      "guest_ids",
      JSON.stringify({ id: guestIds, name: guestNames })
    );

    try {
      const response = await axios.post(
        `https://api.dectwinhotel.com/api/${props.id}_bookings/checkin-room`,
        data,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if (response.status === 201) {
        props.handleToast({
          toastMsg: "Check In Successfully!!!",
          toastColor: "#4CAF50",
        });
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        localStorage.removeItem("rooms");
        navigate("/admin/rooms");
      } else {
        props.handleToast({
          toastMsg: "Something went wrong! Please check data or internet.",
          toastColor: "#FF0000",
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const startCamera = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.play();
          setCameraStarted(true);
        }
      })
      .catch((error) => {
        console.error("Error accessing the camera", error);
      });
  };

  const captureImage = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

    // Set base64 image data for preview in <img>
    const imageData = canvas.toDataURL("image/png");
    setFormData({ ...formData, live_image: imageData });

    // Convert canvas to Blob for uploading
    canvas.toBlob((blob) => {
      if (blob) {
        const file = new File([blob], "live_image.png", { type: "image/png" });
        // Use a different state field to store the file for uploading
        setFormData((prevData) => ({ ...prevData, live_image_file: file }));
      }
    }, "image/png");

    stopCamera();
  };

  const stopCamera = () => {
    const stream = videoRef.current.srcObject;
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
      videoRef.current.srcObject = null;
      setCameraStarted(false);
    }
  };

  const openGuestModal = () => setShowGuestModal(true);
  const closeGuestModal = () => setShowGuestModal(false);

  const addGuestToForm = (guest) => {
    setGuests([...guests, guest]);
  };

  const removeGuest = async (guestId) => {
    try {
      // Remove from the database
      await axios.delete(
        `https://api.dectwinhotel.com/api/${props.id}_guests/remove-guest/${guestId}`
      );

      // Remove from local storage
      localStorage.removeItem(guestId);

      // Remove from UI
      setGuests(guests.filter((guest) => guest.id !== guestId));
    } catch (err) {
      console.error("Error removing guest", err);
    }
  };

  return (
    <section style={{ padding: "1rem" }}>
      <div className="book-room-form">
        <h2>Book Room No {formData.room_no}</h2>
        <form onSubmit={handleSubmit}>
          {guests.length > 0 &&
            guests.map((guest) => (
              <div key={guest.id} className="guest-entry">
                <label htmlFor="guest_name">Guest Name</label>
                <input
                  type="text"
                  name="guest_name"
                  placeholder="Guest Name"
                  value={guest.name}
                  onChange={handleChange}
                  required
                />
                <button type="button" onClick={() => removeGuest(guest.id)}>
                  Remove Guest
                </button>
              </div>
            ))}
          {guests.length > 0 && <br />}
          <button type="button" onClick={openGuestModal}>
            Add Guest
          </button>

          <label htmlFor="from_date">From Date</label>
          <input
            type="datetime-local"
            name="from_date"
            value={formData.from_date}
            onChange={handleChange}
            required
          />

          <label htmlFor="to_date">To Date</label>
          <input
            type="datetime-local"
            name="to_date"
            value={formData.to_date}
            onChange={handleChange}
          />

          <label htmlFor="no_of_stayers">Number of Adult(s)</label>
          <select
            name="no_of_stayers"
            value={formData.no_of_stayers}
            onChange={handleChange}
            required
          >
            <option value="">Select Number of Adult(s)</option>
            {Array.from(
              { length: location.state.max_occupancy },
              (_, i) => i + 1
            ).map((num) => (
              <option key={num} value={num}>
                {num}
              </option>
            ))}
          </select>

          <label htmlFor="paid_amount">Total Amount</label>
          <input
            type="text"
            name="total_amount"
            placeholder="Total Amount"
            maxLength={7}
            value={formData.total_amount}
            onChange={handleNumberChange}
            required
          />

          <label htmlFor="paid_amount">Advance Amount</label>
          <input
            type="text"
            name="advance_amount"
            placeholder="Advance Paid"
            maxLength={7}
            value={formData.advance_amount}
            onChange={handleNumberChange}
            required
          />

          <label>Live Image</label>
          <div
            className="live-image-container"
            style={{
              border: "1px solid #ccc",
              padding: "10px 5rem",
              margin: "10px 0",
            }}
          >
            {!cameraStarted && !formData.live_image && (
              <button
                type="button"
                onClick={startCamera}
                style={{
                  padding: "8px 10px",
                  marginRight: "10px",
                  backgroundColor: "#d32bb5",
                }}
              >
                Open Camera
              </button>
            )}

            <video
              ref={videoRef}
              style={{
                width: "100%",
                height: "auto",
                display: cameraStarted ? "block" : "none",
                marginTop: "10px",
              }}
            ></video>

            {cameraStarted && (
              <canvas
                ref={canvasRef}
                style={{ display: "none" }}
                width="300"
                height="200"
              ></canvas>
            )}

            {cameraStarted && (
              <button
                type="button"
                onClick={captureImage}
                style={{
                  padding: "5px 10px",
                  marginTop: "10px",
                  backgroundColor: "#d32bb5",
                }}
              >
                Capture Image
              </button>
            )}

            {formData.live_image && (
              <div className="live-image-box">
                <span>
                  {formData.live_image
                    ? "Live Image Captured"
                    : "No image captured"}
                </span>
                <img
                  src={formData.live_image}
                  alt="Captured"
                  style={{ width: "100%", height: "auto", marginTop: "10px" }}
                />
              </div>
            )}
          </div>

          <button type="submit">Book Room</button>
        </form>
      </div>
      {showGuestModal && (
        <GuestModal
          onClose={closeGuestModal}
          addGuestToForm={addGuestToForm}
          handleToast={props.handleToast}
          id={props.id}
        />
      )}
    </section>
  );
};

export default BookRoom;
