import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../../styles/admins/invoices/guest.css";

// Modal component for adding a new guest
const GuestModal = ({ onClose, addGuestToForm, handleToast, id }) => {  
  const [guestData, setGuestData] = useState({
    id: null,
    name: "",
    phone: "",
    address: "",
    identity_proof_document: null,
  });
  const [guests, setGuests] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [haveToAdd, setHaveToAdd] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);

  useEffect(() => {
    // localStorage.removeItem("guests");
    // Check if guests are already in localStorage
    const storedGuests = localStorage.getItem("guests");
    if (storedGuests) {
      // Use cached data if available
      setGuests(JSON.parse(storedGuests));
    } else {
      // Fetch guests from the API
      axios
        .get(`https://api.dectwinhotel.com/api/${id}_guests/guests`)
        .then((response) => {
          setGuests(response.data);
          // Store guests in localStorage
          localStorage.setItem("guests", JSON.stringify(response.data));
        })
        .catch((error) => {
          console.error("Error fetching guests:", error);
        });
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setGuestData({
      ...guestData,
      [name]: files ? files[0] : value,
    });
  };

  // Handle input change in the guest name field and fetch suggestions
  const handleNameChange = (e) => {
    const value = e.target.value;
    setGuestData({ ...guestData, name: value });

    if (value.length > 0) {
      // Filter guests based on input
      const filteredSuggestions = guests.filter((guest) =>
        guest.name.toLowerCase().includes(value.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
      setShowSuggestions(true);
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  };

  const handleSuggestionClick = (guest) => {
    // When user clicks a suggestion, autofill the guest data
    setGuestData({
      id: guest.id,
      name: guest.name,
      phone: guest.contact,
      address: guest.address,
      identity_proof_document: guest.identity_proof.split('uploads/')[1],
    });
    setHaveToAdd(true);
    setShowSuggestions(false); // Hide suggestions after selectio
  };

  const handleAddGuest = async () => {
    try {
      // Simple validation
      if (
        !guestData.name ||
        !guestData.phone ||
        !guestData.address ||
        !guestData.identity_proof_document
      ) {
        handleToast({
          toastMsg:
            "Something went wrong! Please check all fields should be filled.",
          toastColor: "#FF0000",
        });
        return;
      }

      if (haveToAdd) {
        addGuestToForm(guestData);
        handleToast({
          toastMsg: "Guest Added Successfully!!!",
          toastColor: "#4CAF50",
        });
        onClose();
        return;
      }

      // Add guest to the database
      const response = await axios.post(
        `https://api.dectwinhotel.com/api/${id}_guests/add-guest`,
        guestData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if (response.status === 200) {
        const newGuest = response.data;

        setGuests([...guests, newGuest]);
        addGuestToForm(newGuest);
        handleToast({
          toastMsg: "Guest Added Successfully!!!",
          toastColor: "#4CAF50",
        });
        onClose();
      }
    } catch (err) {
      handleToast({
        toastMsg:
          "Something went wrong! Please check your internet connection.",
        toastColor: "#FF0000",
      });
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Add Guest</h2>
        <label htmlFor="name">Guest Name</label>
        <input
          type="text"
          name="name"
          placeholder="Guest Name"
          value={guestData.name}
          onChange={handleNameChange}
          required
        />
        {/* Suggestions dropdown */}
        {showSuggestions && suggestions.length > 0 && (
          <ul className="suggestions-list">
            {suggestions.map((guest) => (
              <li
                key={guest.id}
                onClick={() => handleSuggestionClick(guest)}
                className="suggestion-item"
              >
                {guest.name} - {guest.contact}
              </li>
            ))}
          </ul>
        )}

        <label htmlFor="phone">Phone</label>
        <input
          type="text"
          name="phone"
          placeholder="Phone"
          value={guestData.phone}
          onChange={handleChange}
          required
        />

        <label htmlFor="address">Address</label>
        <input
          type="text"
          name="address"
          placeholder="Address"
          value={guestData.address}
          onChange={handleChange}
          required
        />

        <label htmlFor="identity_proof_document">Identity Proof</label>
        {haveToAdd ? (
          <input
            type="text"
            name="identity_proof_document"
            placeholder="Identity Proof"
            value={guestData.identity_proof_document}
            required
          />
        ) : (
          <input
            type="file"
            name="identity_proof_document"
            accept="image/*,application/pdf"
            onChange={handleChange}
            required
          />
        )}

        <button onClick={handleAddGuest}>Add Guest</button>
        <br />
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default GuestModal;
