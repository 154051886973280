import axios from "axios";
const API_BASE_URL = "https://api.dectwinhotel.com/api";

export const fetchRoomsData = (id) => {
  const storedRooms = localStorage.getItem("rooms");
  if (storedRooms) {
    return JSON.parse(storedRooms);
  } else {
    axios
      .get(`${API_BASE_URL}/${id}_rooms/rooms`)
      .then((response) => {
        localStorage.setItem("rooms", JSON.stringify(response.data));
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      });
  }
};

export const fetchFoodsData = (id) => {
  const storedFoods = localStorage.getItem("foods");
  if (storedFoods) {
    return JSON.parse(storedFoods);
  } else {
    axios
      .get(`${API_BASE_URL}/${id}_foods/foods`)
      .then((response) => {
        localStorage.setItem("foods", JSON.stringify(response.data));
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      });
  }
};

export const fetchGuestData = (id) => {
  const storedGuests = localStorage.getItem("guests");
  if (storedGuests) {
    return JSON.parse(storedGuests);
  } else {
    axios
      .get(`${API_BASE_URL}/${id}_guests/guests`)
      .then((response) => {
        localStorage.setItem("guests", JSON.stringify(response.data));
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      });
  }
};