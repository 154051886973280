import React, { useState, useEffect } from "react";
import "../../../styles/admins/foods/order_food.css";
import axios from "axios";
import FoodCard from "../../generals/food_card";

const FoodOrder = ({ isOpen, onClose, bookingId, handleToast, id }) => {
  const [foods, setFoods] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  const updateTotals = () => {
    var quantity = 0;
    var price = 0;
    const storedData = localStorage.getItem(`food_order_${bookingId}`);
    const parsedData = storedData ? JSON.parse(storedData) : [];
    
    for (var items in parsedData) {
      quantity += parsedData[items].quantity;
      price += parsedData[items].price * parsedData[items].quantity;
    }

    setTotalQuantity(quantity);
    setTotalPrice(price);
  };

  useEffect(() => {
    // Check if products are already in localStorage
    const storedFoods = localStorage.getItem("foods");
    if (storedFoods) {
      // Use cached data if available
      setFoods(JSON.parse(storedFoods));
    } else {
      // Fetch products from the API
      axios
        .get(`https://api.dectwinhotel.com/api/${id}_foods/foods`)
        .then((response) => {
          setFoods(response.data);
          // Store products in localStorage
          localStorage.setItem("foods", JSON.stringify(response.data));
        })
        .catch((error) => {
          console.error("Error fetching products:", error);
        });
    }
  }, [id]);

  const handleBookOrder = async () => {
    const storedData = localStorage.getItem(`food_order_${bookingId}`);
    const parsedData = storedData ? JSON.parse(storedData) : [];

    const food_ids = parsedData.map((guest) => guest.food_id);

    try {
      if(totalPrice < 1){
        return;
      }
      const response = await axios.post(
        `https://api.dectwinhotel.com/api/${id}_food_orders/order-foods`,
        {
          booking_id: bookingId,
          food_items: JSON.stringify({ food_id: food_ids }),
          order_total: totalPrice,
        }
      );
      if (response.status === 200) {
        onClose();
        handleToast({
          toastMsg: "Food Ordered Successfully!!!",
          toastColor: "#4CAF50",
        });
        updateTotals();
      } else {
        handleToast({
          toastMsg: "Something went wrong! Please check data or internet.",
          toastColor: "#FF0000",
        });
      }
      // alert("Booking confirmed with total price: ₹" + totalPrice);
    } catch (err) {
      console.error(err);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-backdrop">
      <div className="modals">
        {/* Close button */}
        <button className="close-button" onClick={onClose}>
          &times;
        </button>

        <h3 style={{ fontWeight: "600", color: "black" }}>Add Food Order</h3>

        <div className="food-list">
          {foods.length > 0 ? (
            foods.map((food) => (
              <FoodCard
                key={food.food_id}
                foodData={food}
                bookingId={bookingId}
                updateTotals={updateTotals}
                handleToast={null}
              />
            ))
          ) : (
            <div className="no-rooms-message">
              <i
                style={{ fontSize: "6rem", margin: "30px 10px" }}
                className="fa fa-cutlery"
              ></i>
              <p>No Food Items Added Yet.</p>
            </div>
          )}
        </div>

        {/* Total and Book Section */}
        <div className="total-section">
          <p>Total Quantity: {totalQuantity}</p>
          <p>Total Price: ₹{totalPrice}</p>
          <button className="book-button" onClick={handleBookOrder}>
            Order Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default FoodOrder;
