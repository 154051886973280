import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import DeleteFood from "../admins/foods/delete_food";

const cardStyles = {
  backgroundColor: "#ffffff",
  width: "18rem",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  borderRadius: "8px",
  overflow: "hidden",
  border: "1px solid #ddd",
  margin: "20px",
  transition: "box-shadow 0.3s ease, transform 0.3s ease",
};

const imgContainerStyles = {
  overflow: "hidden",
  transition: "transform 0.3s ease",
};

const imgStyles = {
  width: "100%",
  height: "140px",
  objectFit: "cover",
};

const cardBodyStyles = {
  padding: "15px",
};

const cardTitleStyles = {
  fontSize: "1.25rem",
  margin: "0",
  fontWeight: "bold",
  color: "#000000",
};

const priceContainerStyles = {
  marginBottom: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontSize: "1.2rem",
};

const discountedPriceStyles = {
  color: "#000000",
  fontWeight: "600",
};

const cardTextStyles = {
  fontSize: "1rem",
  margin: "0",
  color: "#555",
};

const editButton = {
  backgroundColor: "#007bff",
  border: "none",
  color: "#fff",
  padding: "6px 20px",
  borderRadius: "4px",
  cursor: "pointer",
  fontSize: "1rem",
  fontWeight: "600",
};

const deleteButton = {
  backgroundColor: "#ff0000",
  border: "none",
  color: "#fff",
  padding: "6px 10px",
  borderRadius: "4px",
  cursor: "pointer",
  fontSize: "1rem",
  fontWeight: "600",
};

const quantButton = {
  backgroundColor: "#ff00ff",
  border: "none",
  color: "#fff",
  padding: "6px 16px",
  borderRadius: "4px",
  cursor: "pointer",
  fontSize: "1rem",
  fontWeight: "600",
};

function FoodCard({ foodData, bookingId, updateTotals, handleToast, id }) {
  const [isAvailable, setIsAvailable] = useState(foodData.availability_status);
  const [quantity, setQuantity] = useState(() => {
    const storedData = localStorage.getItem(`food_order_${bookingId}`);
    const parsedData = storedData ? JSON.parse(storedData) : [];
    const foodItem = parsedData.find(
      (item) => item.food_id === foodData.food_id
    );
    return foodItem ? foodItem.quantity : 0;
  });

  const [showModal, setShowModal] = useState(false);

  async function onDelete() {
    await axios.delete(
      `https://api.dectwinhotel.com/api/${id}_foods/delete-food/${foodData.food_id}`
    );
    localStorage.removeItem("foods");
    toggleModal();
    window.location.reload();
  }

  function toggleModal() {
    setShowModal(!showModal);
  }

  const updateLocalStorage = (newQuantity) => {
    let storedData = localStorage.getItem(`food_order_${bookingId}`);
    let parsedData = storedData ? JSON.parse(storedData) : [];

    // Find the item in the list
    const existingItemIndex = parsedData.findIndex(
      (item) => item.food_id === foodData.food_id
    );

    if (existingItemIndex > -1) {
      // Update the existing item
      parsedData[existingItemIndex].quantity = newQuantity;
    } else {
      // Add new item
      parsedData.push({
        food_id: foodData.food_id,
        food_name: foodData.food_name,
        price: foodData.price,
        quantity: newQuantity,
      });
    }

    // Filter out items with zero quantity
    parsedData = parsedData.filter((item) => item.quantity > 0);

    // Update localStorage
    localStorage.setItem(`food_order_${bookingId}`, JSON.stringify(parsedData));

    return parsedData;
  };

  const handleAvailabilityChange = async (e) => {
    const newAvailability = e.target.checked;

    try {
      const response = await axios.put(
        `https://api.dectwinhotel.com/api/${id}_foods/update-food-availability/${foodData.food_id}`,
        { available: newAvailability }
      );

      if (response.status === 200) {
        handleToast({
          toastMsg: "Availability Status changed Successfully!!!",
          toastColor: "#4CAF50",
        });
        setIsAvailable(newAvailability);
        localStorage.removeItem("foods");
      }
    } catch (error) {
      handleToast({
        toastMsg: "Something went wrong! Please check input data or internet.",
        toastColor: "#FF0000",
      });
    }
  };

  const handleIncreaseQuantity = () => {
    setQuantity((prevQuantity) => {
      const newQuantity = prevQuantity + 1;
      updateLocalStorage(newQuantity);
      updateTotals();
      return newQuantity;
    });
  };

  const handleDecreaseQuantity = () => {
    setQuantity((prevQuantity) => {
      if (prevQuantity > 0) {
        const newQuantity = prevQuantity - 1;
        updateLocalStorage(newQuantity);
        updateTotals();
        return newQuantity;
      }
      return prevQuantity;
    });
  };

  return foodData.availability_status || handleToast !== null ? (
    <div style={cardStyles} className="card-hover">
      <div className="img-container" style={imgContainerStyles}>
        <img
          src="https://img.freepik.com/free-photo/top-view-bowls-with-indian-food_23-2148723454.jpg?t=st=1728891194~exp=1728894794~hmac=0ea800d4c45bae1728793a077945a1b98573333e31e4c9da3b310d3a951a1c45&w=996"
          alt="Card top"
          style={imgStyles}
        />
      </div>
      <div style={cardBodyStyles}>
        <h5 style={cardTitleStyles}>{foodData.food_name}</h5>

        <p style={cardTextStyles}>Category: {foodData.category}</p>
        <p style={cardTextStyles}>
          Description:{" "}
          {foodData.food_description === ""
            ? "Not Available"
            : foodData.food_description}
        </p>

        {/* Price Section */}
        <div style={priceContainerStyles}>
          <span style={discountedPriceStyles}>Price: ₹{foodData.price}</span>
          {handleToast !== null && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ fontSize: "1rem", fontWeight: "600" }}>
                Ready
              </label>
              <input
                type="checkbox"
                checked={isAvailable}
                onChange={handleAvailabilityChange}
                style={{
                  marginLeft: "10px",
                  marginTop: "0px",
                  width: "18px",
                  height: "18px",
                  cursor: "pointer",
                }}
              />
            </div>
          )}
        </div>

        {/* Quantity Section */}
        {bookingId === null ? (
          <div
            className="rooms-data"
            style={{ justifyContent: "space-between" }}
          >
            <Link to="/admin/add-food" state={foodData}>
              <button style={editButton}> Edit Food </button>
            </Link>
            <button style={deleteButton} onClick={toggleModal}>
              Delete Food
            </button>
          </div>
        ) : (
          <div
            className="rooms-data"
            style={{
              justifyContent: "space-around",
              display: "flex",
              alignItems: "center",
            }}
          >
            <button style={quantButton} onClick={handleDecreaseQuantity}>
              {" "}
              -{" "}
            </button>
            <span style={{ color: "black", fontWeight: "600" }}>
              {quantity}
            </span>
            <button style={quantButton} onClick={handleIncreaseQuantity}>
              {" "}
              +{" "}
            </button>
          </div>
        )}
      </div>
      {showModal && (
        <DeleteFood
          foodName={foodData.food_name}
          onDelete={onDelete}
          showModals={toggleModal}
        ></DeleteFood>
      )}
    </div>
  ) : null;
}

export default FoodCard;
